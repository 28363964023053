import React from 'react'

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <p>
          &copy; 2024 Tareq El Sayyed. All rights reserved. <br />
          Website built by <a href="https://tareqelsayyed.onrender.com/" target="_blank" rel="noopener noreferrer">Tareq El Sayyed</a>.
        </p>
      </div>
    </footer>
  )
}

export default Footer
